<template>
  <div class="face">
    <canvas id="canvas" style="margin: 0 auto; display: block" width="160px" height="160px"></canvas>
    <div class="tips">请连接公司 WIFI，扫描二维码，录入人脸。一人一码，请勿分享</div>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      userId: "",
      name: "",
      mobile: "",
      url: "http://10.23.67.170:8989/"
    };
  },

  watch: {
    userId() {
      if (this.userId) {
        const canvas = document.getElementById("canvas");
        const context = canvas.getContext("2d");
        console.log(
          `${this.url}?id=${this.userId}&name=${this.name}&mobile=${this.mobile}`
        );

        new window.AwesomeQR.AwesomeQR({
          text: `${this.url}?id=${this.userId}&name=${this.name}&mobile=${this.mobile}`,
          size: 160,
          margin: 0
        })
          .draw()
          .then(dataURL => {
            const image = new Image();
            image.onload = function() {
              context.drawImage(image, 0, 0);
            };
            image.src = dataURL;
          });
      }
    }
  },

  mounted() {
    // 1. 恢复用户基本信息
    const str = window.localStorage.getItem("user");
    if (str) {
      const user = JSON.parse(str);

      this.userId = user.uuid;
      this.name = user.userName;
      this.mobile = user.mobile;
    }
  }
};
</script>
  
  <style lang="less" scoped>
.face {
  padding: 16px;
}
.tips {
  text-align: center;
  color: #999;
  margin-top: 16px;
}
</style>