<template>
  <div>
    <div class="between item">
      <a-alert
        v-if="hasNotificationPermission"
        message="您已允许此浏览器为您发送通知信息！"
        type="success"
        show-icon
      />

      <a-alert
        v-if="hasNotificationPermission === false"
        message="您已拒绝此浏览器为您发送通知信息！如需此功能，请至浏览器设置页面进行设置！"
        type="error"
        show-icon
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      hasNotificationPermission: null,
    };
  },
  mounted() {
    this.getNotificationPermission()
      .then(() => {
        this.hasNotificationPermission = true;
      })
      .catch((err) => {
        this.hasNotificationPermission = false;
        this.$error({
          title: err,
        });
      });
  },

  methods: {
    getNotificationPermission() {
      return new Promise((resolve, reject) => {
        if (!window.Notification) {
          reject("您的浏览器不支持此功能，请更换浏览器！");
        } else {
          if (window.Notification.permission === "granted") {
            resolve();
          } else if (window.Notification.permission === "default") {
            window.Notification.requestPermission().then((permission) => {
              if (permission === "granted") {
                resolve();
              } else if (permission === "default") {
                reject("请点击允许按钮！");
              } else {
                reject(
                  "您已拒绝授权，如需此功能，请到浏览器设置页面进行设置！"
                );
              }
            });
          } else {
            reject("您已拒绝授权，如需此功能，请到浏览器设置页面进行设置！");
          }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.item {
  width: 100%;
  padding: 8px 0;
  .title {
    font-weight: bold;
  }
}
</style>