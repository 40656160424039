<template>
  <div>
    <div class="pane">
      <div class="title">个人设置</div>
      <div class="remarks">个人设置页面</div>
    </div>
    <a-card class="container">
      <a-row>
        <a-col :span="3">
          <a-menu style="width: 100%" mode="inline" v-model="activeKey">
            <a-menu-item key="1">基本设置</a-menu-item>
            <a-menu-item key="2">安全设置</a-menu-item>
            <a-menu-item key="3">消息通知</a-menu-item>
            <a-menu-item key="4">人脸录入</a-menu-item>
          </a-menu>
        </a-col>
        <a-col :span="21">
          <div class="view">
            <div v-if="selectedKey === '1'">
              <div class="title">基本设置</div>

              <div class="base-info">
                <base-info />
                <Avatar />
              </div>
            </div>

            <div v-if="selectedKey === '2'">
              <div class="title">安全设置</div>
              <div style="width: 320px">
                <PasswordEditor />
              </div>
            </div>

            <div v-if="selectedKey === '3'">
              <div class="title">消息通知</div>
              <Notification />
            </div>

            <div v-if="selectedKey === '4'">
              <div class="title">人脸录入</div>
              <Face />
            </div>
          </div>
        </a-col>
      </a-row>
    </a-card>
  </div>
</template>

<script>
import BaseInfo from "./base-info.vue";
import Avatar from "./avatar";
import PasswordEditor from "@/components/password-editor";
import Notification from "./notification.vue";
import Face from "./face.vue";
export default {
  components: {
    BaseInfo,
    Avatar,
    PasswordEditor,
    Notification,
    Face
  },
  data() {
    return {
      activeKey: ["1"]
    };
  },
  computed: {
    selectedKey() {
      if (this.activeKey.length > 0) {
        return this.activeKey[0];
      } else {
        return "";
      }
    }
  }
};
</script>

<style lang="less" scoped>
.pane {
  background-color: #fff;
  padding: 16px 32px;
  .title {
    font-size: 18px;
    color: rgba(0, 0, 0, 0.85);
  }
  .remarks {
    margin-top: 8px;
    font-size: 13px;
    color: rgba(0, 0, 0, 0.45);
  }
}

.view {
  padding: 8px 24px;
  .title {
    margin-bottom: 12px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
  }
  .base-info {
    display: flex;
    align-items: flex-start;
  }
}
</style>