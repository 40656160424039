<template>
  <div>
    <div class="label">头像</div>
    <div class="avatar">
      <a-avatar :src="user.header" :size="144" />
    </div>
    <div class="center">
      <FileUpload :multiple="false" @uploaded="uploaded">
        <a-button icon="upload"> 更换头像</a-button>
      </FileUpload>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { editBaseInfo } from "@/api/setting/user";
import FileUpload from "@/components/file-upload";
export default {
  components: {
    FileUpload,
  },

  data() {
    return {
      headers: {
        token: window.localStorage.getItem("token"),
      },
    };
  },
  computed: {
    ...mapState("auth", ["user"]),
  },
  methods: {
    uploaded(arr) {
      if (arr.length > 0) {
        this.updateAvatar(arr[0].completePath);
      }
    },

    ...mapMutations("auth", ["setUser"]),

    updateAvatar(path) {
      const params = {
        ...this.user,
        header: path,
      };

      console.log("params", params);

      editBaseInfo(params).then((res) => {
        console.log("res", res);
        this.setUser(params);
      });
    },
  },
};
</script>

<style lang="less" scoped>
.label {
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 40px;
}
.avatar {
  margin-bottom: 12px;
}
</style>