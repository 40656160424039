<template>
  <div class="form">
    <a-form-model ref="form" :model="form" :rules="rules" :colon="false">
      <a-form-model-item label="用户名" prop="name">
        <a-input v-model="form.name" :disabled="true" />
      </a-form-model-item>
      <a-form-model-item label="姓名" prop="userName">
        <a-input v-model="form.userName" :disabled="true" />
      </a-form-model-item>
      <a-form-model-item label="手机号码" prop="mobile">
        <a-input v-model="form.mobile" />
      </a-form-model-item>
      <a-form-model-item label="部门" prop="deptId">
        <a-select
          :disabled="!isHr"
          placeholder=""
          show-search
          :filter-option="$selectFilterOption"
          style="width: 100%"
          v-model="form.deptId"
        >
          <a-select-option
            v-for="item in organizationList"
            :key="item.id"
            :value="item.id"
            >{{ item.uniqueName }}</a-select-option
          >
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="邮箱" prop="email">
        <a-input v-model="form.email" />
      </a-form-model-item>

      <a-form-model-item label="签名图片">
        <Uploader
          type="image"
          :list="fileList"
          @change="(arr) => (fileList = arr.map((item) => item.completePath))"
        >
        </Uploader>
      </a-form-model-item>

      <a-form-model-item label="备注" prop="remarks">
        <a-textarea :auto-size="{ minRows: 3 }" v-model="form.remarks" />
      </a-form-model-item>

      <a-button type="primary" :loading="loading" @click="save"
        >更新基本信息</a-button
      >
    </a-form-model>
  </div>
</template>

<script>
import organization from "@/mixins/organization";
import { mapMutations, mapState, mapGetters } from "vuex";
import { fetchDetail, editBaseInfo } from "@/api/setting/user";
import Uploader from "@/components/uploader";
export default {
  mixins: [organization],

  components: {
    Uploader,
  },

  data() {
    return {
      loading: false,
      rules: {
        name: [{ required: true, message: "请输入", trigger: "blur" }],
        userName: [{ required: true, message: "请输入", trigger: "blur" }],
        mobile: [{ required: true, message: "请输入", trigger: "blur" }],
        deptId: [{ required: true, message: "请输入", trigger: "blur" }],
        email: [{ required: true, message: "请输入", trigger: "blur" }],
        remarks: [{ required: false }],
      },
      form: {},

      fileList: [], // 签名列表，只上传一张
    };
  },
  computed: {
    ...mapState("auth", ["user"]),
    ...mapGetters("auth", ["isHr"]),
  },

  mounted() {
    this.getDetail();
  },
  methods: {
    ...mapMutations("auth", ["setUser"]),

    getDetail() {
      fetchDetail({
        uuid: this.user.uuid,
      }).then((res) => {
        if (res.uuid) {
          this.setUser(res);
          if (res.sign) {
            this.fileList = [res.sign];
          }
          this.form = {
            ...res,
            deptId: res.employeeVo ? res.employeeVo.deptId : null,
          };
        }
      });
    },

    save() {
      this.loading = true;
      this.$refs.form.validate((valid) => {
        if (valid) {
          editBaseInfo({
            ...this.form,
            sign: this.fileList.length > 0 ? this.fileList[0] : undefined,
          })
            .then(() => {
              this.loading = false;
              this.getDetail();
            })
            .catch(() => {
              this.loading = false;
            });
        } else {
          this.loading = false;
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.form {
  width: 340px;
  margin-right: 104px;
}
</style>